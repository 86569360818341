<template>
  <div>
    <filter-slot
      :filterPrincipal="filterPrincipal"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refSentList"
          small
          :items="getAllData"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <div>
              <span class="text-primary">{{ data.item.client_name }}</span>
              <br />
              <span>{{ data.item.account }}</span>
            </div>
          </template>

          <template #cell(user_name)="data">
            <div>
              <span>{{ data.item.user_name }}</span>
              <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>

          <template #cell(letter_format)="data">
            <span v-if="data.item.letter">{{ data.item.letter }}</span>
            <span v-else>Statement</span>
          </template>

          <template #cell(program)="data">
            <span>{{
              data.item.letter_client_type_id == 15
                ? "Debt Solution"
                : data.item.program
            }}</span>
          </template>

          <template #cell(letter_print)="data">
            <div v-if="data.item.letter_client_type_id !== 15">
              <div v-if="data.item.letter">
                <div v-if="data.item.program_id == 3">
                  <feather-icon
                    icon="PrinterIcon"
                    class="text-primary"
                    size="20"
                    @click="pdfletterclient(data.item)"
                    style="cursor: pointer"
                  >
                  </feather-icon>
                </div>
                <div v-if="data.item.program_id == 2">
                  <feather-icon
                    icon="PrinterIcon"
                    class="text-primary"
                    size="20"
                    target="_blank"
                    @click="pdfLetterClientBc(data.item)"
                    style="cursor: pointer"
                  >
                  </feather-icon>
                </div>
              </div>
              <div v-else>
                <a :href="data.item.route_file" target="_blank">
                  <feather-icon
                    icon="PrinterIcon"
                    class="text-primary"
                    size="20"
                    style="cursor: pointer"
                  >
                  </feather-icon>
                </a>
              </div>
            </div>
            <div
              style="min-width: 150px"
              class="py-1"
              v-if="data.item.letter_client_type_id === 15"
            >
              <!-- <div class="d-flex">
                <a
                  :href="item.url"
                  target="_blank"
                  style="margin-top: 6px"
                  v-for="(item, index) in data.item.url"
                  :key="index"
                >
                  <amg-icon
                    v-b-tooltip.hover
                    :title="item.name"
                    icon="FilePdfIcon"
                    class="cursor-pointer"
                    size="15"
                  />
                </a>
              </div> -->

              <b-row>
                <b-col
                  cols="4"
                  v-for="(element, index) in data.item.url"
                  :key="index"
                >
                  <a target="_blank" :href="element.url">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <amg-icon
                        class="cursor-pointer bigger text-danger"
                        icon="FilePdfIcon"
                      />
                      <b-badge
                        variant="light-primary"
                        style="margin-top: 5px"
                      >
                        {{ element.name }}
                      </b-badge>
                    </div>
                  </a>
                </b-col>
              </b-row>
            </div>
          </template>

          <template #cell(sent_date)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span>
                {{ data.item.sent_date | myGlobalDay }}
              </span>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import filters from "../data/filters.pending.data";
import fields from "../data/fields.sent.data";
import LettersToClientsService from "../../service/lettersToClients.service";
export default {
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "created_at",
      sortDesc: true,
      fields: fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isLetterClient() {
      return this.$route.meta.client;
    },
  },
  methods: {
    async pdfLetterClientBc(data) {
      try {
        const params = {
          accountid: data.client_account_id,
          type: data.letter_client_type_id,
        };
        this.addPreloader();
        const response = await LettersToClientsService.openPdfLetterClientBc(
          params
        );
        if (response.status == 200) {
          this.forceFileDownload(response.data, "document.pdf", "blank");
        }
        this.removePreloader();
      } catch (exception) {
        console.error(exception);
      }
    },
    async pdfletterclient(data) {
      try {
        const params = {
          accountid: data.client_account_id,
          type: data.letter_client_type_id,
        };
        this.addPreloader();
        const response = await LettersToClientsService.openPdfLetterClient(
          params
        );
        if (response.status == 200) {
          this.forceFileDownload(response.data, "document.pdf", "blank");
        }
        this.removePreloader();
      } catch (exception) {
        console.error(exception);
      }
    },
    refresh() {
      this.$refs.refSentList.refresh();
    },
    async getAllData() {
      try {
        const params = {
          status: 2,
          orderby: 12,
          order: "desc",
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          program: this.filter[2].model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          client_sp: this.isLetterClient,
        };
        const response = await LettersToClientsService.getDataLettersToClients(
          params
        );
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped></style>
